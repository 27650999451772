import Swal from 'sweetalert2'

const defaultParams = {
  customClass: {
    confirmButton: 'ff-primary w-100 mb-0 button-primary-local btn-h-36 fs-14 fw-600',
    cancelButton: 'ff-primary w-100 mb-0 button-link-local text-decoration-none btn-h-36 fs-14 fw-600 s-mt-5',
    content: 'p-0',
    header: 'p-0'
  },
  buttonsStyling: false,
  padding: '24px'
}

function swalFire ({
  width = 304,
  imageWidth = 192,
  imageUrl,
  title,
  message,
  confirmButtonText,
  animation = true,
  showCancelButton = false,
  cancelButtonText = 'Batal',
  allowOutsideClick = true
}) {
  return Swal.fire({
    ...defaultParams,
    width,
    imageWidth,
    showCancelButton,
    cancelButtonText,
    confirmButtonText,
    allowOutsideClick,
    title: `<p class="ff-primary mb-0 text-persian-blue-900 fs-18">${title}</p>`,
    html: `<p class="ff-primary mb-0 text-ui-primary fs-14 fl-20">${message}</p>`,
    imageUrl,
    animation
  })
}

export { swalFire }
