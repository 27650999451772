<template>
  <div class="email-dropdown-wrapper" v-click-outside="clickOutsideConfig">
    <div class="input-button-wrapper">
      <s-input-text
        v-bind="$attrs"
        v-on="$listeners"
        ref="email"
        type="email"
        is-input-return-event
        :value="email"
        :class="inputClasses"
        :is-error="isError"
        @focus="handleEmailInputFocus"
        @input="handleInputEvent"
        @keyup.native.up="handleListNavigation('up')"
        @keyup.native.down="handleListNavigation('down')"
        @keyup.native.esc="handleEscPress"
        autocorrect="off"
        autocomplete="off"
        autocapitalize="off"
      />
    </div>
    <div :class="emailDropdownContainerClasses">
      <ul v-if="shouldShowList" class="email-dropdown-list">
        <li
          v-for="(domain, index) in domainsList"
          :key="index"
          tabindex="-1"
          :data-dropdown-item-index="index"
          class="email-dropdown-item"
          @click="handleOptionSelection(domain)"
          @keyup.esc="handleEscPress"
          @keyup.enter="handleOptionSelection(domain)"
          @keyup.up="handleListNavigation('up')"
          @keyup.down="handleListNavigation('down')"
          @keyup="convertCharToText"
        >
          <span class="email-dropdown-item-username">{{ username }}</span>
          <span class="email-dropdown-item-domain">@{{ domain }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vClickOutside from 'v-click-outside'
import keycoder from 'keycoder'
import SInputText from '@/components/atoms/inputs/SInputText.vue'

Vue.use(vClickOutside)
export default {
  name: 'email-auto-complete',
  inheritAttrs: false,
  components: {
    SInputText
  },
  props: {
    initialValue: {
      type: String,
      default: ''
    },
    domains: {
      type: Array,
      required: true
    },
    defaultDomains: {
      type: Array,
      default () {
        return []
      }
    },
    maxSuggestions: {
      type: Number,
      default: 4,
      validator (num) {
        const isInteger = Number.isInteger(num)
        if (!isInteger) {
          throw new Error(
            `Invalid prop: type check failed for prop "maxSuggestions". Expected Integer but got ${num} as value.`
          )
        }
        return isInteger
      }
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true
    },
    inputClasses: {
      type: [String, Array, Object],
      default: ''
    },
    isError: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      email: this.initialValue,
      isEscPressed: false,
      listFocusIndex: 0,
      isFirstFocus: false,
      hasclickedOutside: false,
      clickOutsideConfig: {
        handler: this.clickOutsideHandler,
        middleware: this.middleware,
        events: ['dblclick', 'click'],
        isActive: true
      }
    }
  },
  computed: {
    shouldShowList () {
      return Boolean(this.domainsList.length && !this.isOptionSelected && !this.isEscPressed)
    },
    includesAt () {
      return this.email.toLowerCase().includes('@')
    },
    username () {
      return this.email.toLowerCase().split('@')[0]
    },
    emailDropdownContainerClasses () {
      return {
        'email-dropdown-list-container': true,
        hide: this.hasclickedOutside
      }
    },
    domain () {
      return this.email.toLowerCase().split('@')[1] || ''
    },
    suggestionList () {
      return this.domainsList.map(domain => `${this.username}@${domain}`.toLowerCase())
    },
    isOptionSelected () {
      return this.suggestionList.includes(this.email.toLowerCase())
    },
    domainsList () {
      if (!this.includesAt || this.email.length <= 1 || (this.email.match(/@/g) || []).length > 1) {
        return []
      }
      if (!this.domain.length && this.defaultDomains.length) {
        return this.defaultDomains.slice(0, this.maxSuggestions)
      }
      if (!this.domain) {
        return []
      }
      return this.domains
        .filter(domain => domain.startsWith(this.domain))
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .slice(0, this.maxSuggestions)
    }
  },
  methods: {
    convertCharToText (event) {
      const INVALID_KEYS = {
        ENTER: 13,
        UP: 38,
        DOWN: 40
      }
      const eventObject = keycoder.fromEvent(event)
      const isValidEventChar = eventObject && !Object.values(INVALID_KEYS).includes(eventObject.charCode)
      if (isValidEventChar) {
        const char = keycoder.eventToCharacter(event)
        if (char) {
          this.email += char
          this.$refs.email.focus()
        }
      }
    },
    clickOutsideHandler () {
      this.hasclickedOutside = true
    },
    handleInputEvent ({ target: { value: email } }) {
      this.email = email.toLowerCase()
    },
    handleOptionSelection (domain) {
      this.email = `${this.username}@${domain}`
      this.$refs.email.focus()
      this.listFocusIndex = 0
    },
    middleware ({ target }) {
      const isDropdownItem =
        target.className === 'email-dropdown-item' && target.parentNode.className.includes('email-dropdown-list')
      return this.closeOnClickOutside && !isDropdownItem
    },
    handleEscPress () {
      this.isEscPressed = true
      this.$refs.email.focus()
    },
    handleEmailInputFocus () {
      this.hasclickedOutside = false
      this.resetFocusIndex()
    },
    resetFocusIndex () {
      this.isFirstFocus = false
      this.listFocusIndex = 0
    },
    handleListNavigation (direction) {
      if (!this.shouldShowList || this.shouldFocusInput(direction)) return
      const shouldScrollUp = direction === 'up' && this.listFocusIndex >= 1
      const shouldSchollDown = direction === 'down' && this.listFocusIndex < this.domainsList.length - 1
      if (this.isFirstFocus) {
        if (shouldScrollUp) {
          this.listFocusIndex -= 1
        } else if (shouldSchollDown) {
          this.listFocusIndex += 1
        }
      } else {
        this.isFirstFocus = true
      }
      this.$nextTick(() => {
        document.querySelector(`[data-dropdown-item-index="${this.listFocusIndex}"]`).focus()
      })
    },
    shouldFocusInput (direction) {
      const shouldFocus = direction === 'up' && this.listFocusIndex === 0
      if (shouldFocus) {
        this.$refs.email.focus()
        this.resetFocusIndex()
      }
      return shouldFocus
    }
  },
  watch: {
    email () {
      this.$emit('input', this.email)
      this.resetFocusIndex()
      if (this.isEscPressed) {
        this.isEscPressed = false
      }
    }
  }
}
</script>

<style scoped>
.email-dropdown-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.email-dropdown-wrapper .input-button-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
}
.email-dropdown-wrapper input {
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 0;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-top: 10px;
}
.email-dropdown-wrapper input.input-error {
  border: 1px solid #eb5757;
}
.email-dropdown-wrapper button {
  padding: 0;
  margin-left: -11.8px;
  margin-right: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
button:focus {
  outline: none;
}
.email-dropdown-wrapper .email-dropdown-list-container {
  position: relative;
  height: 0;
  top: 5px;
}
email-dropdown-list-container.hide {
  display: none;
}
.email-dropdown-wrapper .email-dropdown-list {
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  background-color: var(--color-neutral-100);
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  /* border: 1px solid #e0e0e0; */
  box-sizing: border-box;
  margin-top: -0.9px;
  border-radius: 0 0 10px 10px;
}
.email-dropdown-list .email-dropdown-item {
  cursor: pointer;
  padding-left: 2px;
  padding: 0.375rem 0.75rem;
}
.email-dropdown-item:first-child {
  border-top: none;
}
.email-dropdown-item:hover,
.email-dropdown-item:focus {
  background-color: #f2f2f2;
  box-sizing: border-box;
}
.email-dropdown-item-username {
  color: #999;
}
.email-dropdown-item-domain {
  color: #101920;
  font-weight: 500;
}

/* customized class */
.register-input{
  margin-top: 10px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  color: var(--color-ui-text-primary);
}

.register-input::placeholder {
  color: var(--color-ui-text-placeholder);
}
</style>
