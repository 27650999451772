<template>
  <div id="evaluation-sidebar-activity-v">
    <span
      class="d-block inter-medium_small-normal text-center text-ui-primary s-m-5"
    >
      {{ textTitle }}
    </span>
    <div class="button-wrapper">
      <s-button
        :id="'report-button-' + index"
        :key="'report-button-' + index"
        v-for="(button, index) in buttons"
        :variant="button.variant"
        :disabled="button.disabled"
        :class="button.btnClass"
        @onClick="button.clickEvent"
        class="position-relative"
      >
        <img
          class="icon-wrapper"
          v-if="button.iconUrl"
          :alt="button.label"
          :src="button.iconUrl"
        />
        <span v-else>
          {{ button.label }}
        </span>
      </s-button>
    </div>
    <s-modal
      :is-show="reportModalValidation.isShow"
      :modal-title="reportModalValidation.title"
      :submit-button-text="reportModalValidation.btnText"
      :modal-text="reportModalValidation.info"
      :modal-image="reportModalValidation.image"
      @submitModal="
        reportModalValidation.isShow = !reportModalValidation.isShow
      "
    >
    </s-modal>
  </div>
</template>
<script>
import SButton from '@/components/atoms/buttons/SButton.vue'
import ProgramActivityAPI from '@/api/program-activity'
import { mapState } from 'vuex'
import SModal from '@/components/molecules/modals/SModal.vue'
import { formatDateMixin } from '@/mixins/formatDateMixin'
import { swalFire } from '@/functions/swalHelper'
import recalculateInfoMixin from '@/mixins/recalculateInfoMixin.js'

export default {
  name: 'EvaluationSidebar',
  components: {
    SButton,
    SModal
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  mixins: [formatDateMixin, recalculateInfoMixin],
  data () {
    return {
      buttons: [],
      reportModalValidation: {
        isShow: false,
        title: 'Laporan Belum Tersedia',
        info: 'Laporan Belajar akan tersedia pada [Tanggal Laporan Belajar Tersedia].',
        btnText: 'Mengerti',
        image:
          'https://cdn.sekolah.mu/assets/v2/empty-state/belum-ada-portfolio.png'
      }
    }
  },
  computed: {
    ...mapState('storage', ['accessToken', 'userId']),
    ...mapState('activityV2', ['validate', 'report']),
    isCertificateDisabled () {
      return !this.report.can_view_certificate
    },
    textTitle () {
      const isCompleted = this.report.percentage_progress === 100
      const totalButton = this.buttons.length
      if (totalButton === 3) {
        return isCompleted
          ? 'Kamu sudah menyelesaikan seluruh aktivitas, klik tombol dibawah ini untuk mendapatkan sertifikat.'
          : 'Ayo ikuti seluruh aktivitas kelas untuk mendapatkan sertifikat.'
      }
      if (totalButton === 2) {
        return isCompleted
          ? 'Kamu sudah menyelesaikan seluruh aktivitas, pelajari kembali aktivitas agar kamu dapat mencapai kompetensi terbaikmu.'
          : 'Ayo selesaikan seluruh aktivitas, untuk kembangkan kemampuan sesuai dengan kompetensi terbaikmu.'
      }
      if (totalButton === 1) {
        return 'Klik tombol dibawah ini untuk melihat portofoliomu.'
      }
    }
  },
  methods: {
    async getReport () {
      const { data } = await ProgramActivityAPI.getReport({
        data: {
          user_id: this.userId,
          program_user_id: this.validate.program_user_id,
          id: this.validate.program_id
        },
        Authorization: this.accessToken
      })
      if (data.status === 200) {
        this.$store.commit('activityV2/SET_REPORT', data.data)
        this.generateBtn()
      }
    },
    showReport () {
      if (
        this.report.scoring_date &&
        Date.parse(new Date()) < Date.parse(this.report.scoring_date)
      ) {
        this.reportModalValidation.isShow = true
        this.reportModalValidation.info = `Laporan Belajar akan tersedia pada ${this.formatDate(
          this.report.scoring_date,
          'LL',
          true
        )}.`
      } else {
        const route = this.$router.resolve({
          path: `/rapor/${this.validate.program_user_id}/lembaga`
        })
        if (this.recalculating && this.isMobile) {
          swalFire({
            imageUrl: 'https://cdn.sekolah.mu/assets/v2/laporan-belajar/sedang-proses-hitung-ulang.webp',
            imageWidth: 192,
            title: 'Sedang Proses <br/> Hitung Ulang Nilai',
            message: 'Proses hitung ulang nilai sedang berlangsung. Nilai yang ditampilkan saat ini akan berubah jika proses hitung ulang nilai sudah selesai.',
            confirmButtonText: 'Mengerti',
            showCancelButton: false,
            allowOutsideClick: false
          }).then(async result => {
            if (result.isConfirmed) {
              window.open(route.href, '_blank')
            }
          })
        } else {
          window.open(route.href, '_blank')
        }
      }
    },
    generateBtn () {
      const extraLabel = !this.isMobile ? 'Lihat ' : ''
      const defaultButtons = [
        {
          label: this.isMobile ? `${extraLabel}Laporan` : `${extraLabel}Laporan Belajar`,
          disabled: false,
          btnClass: 'd-block m-2 w-100',
          variant: 'secondary',
          iconUrl: null,
          clickEvent: this.showReport
        },
        {
          label: `${extraLabel}Sertifikat`,
          disabled: this.isCertificateDisabled,
          btnClass: 'd-block m-2 w-100',
          variant: 'secondary',
          iconUrl: null,
          clickEvent: () => {
            const route = this.$router.resolve({
              path: `/sertifikat/${this.validate.program_user_id}`
            })
            window.open(route.href, '_blank')
          }
        },
        {
          label: `${extraLabel}Portofolio`,
          disabled: false,
          btnClass: 'd-block m-2 w-100',
          variant: 'secondary',
          iconUrl: null,
          clickEvent: () => {
            this.$router.push('/portofolio/profil')
          }
        }
      ]
      if (!this.report.show_certificate && !this.report.show_report) {
        defaultButtons.splice(0, 2)
      } else if (!this.report.show_certificate && this.report.show_report) {
        defaultButtons.splice(1, 1)
      } else if (this.report.show_certificate && !this.report.show_report) {
        defaultButtons.splice(0, 1)
      }
      if (this.isMobile) {
        if (defaultButtons.length === 3) {
          const porfolioBtn = defaultButtons[2]
          porfolioBtn.iconUrl =
            'https://cdn.sekolah.mu/assets/v2/menu-icon/portfolio.svg'
          porfolioBtn.btnClass = 'd-block m-2'
        }
        defaultButtons.reverse()
        defaultButtons[defaultButtons.length - 1].variant = 'primary'
      } else {
        defaultButtons[0].variant = 'primary'
      }
      this.buttons = defaultButtons
    }
  },
  mounted () {
    this.getReport()
  }
}
</script>
<style scoped>
.icon-wrapper {
  height: 24px;
  width: 24px;
}
.button-wrapper {
  display: flex;
}
@media (min-width: 992px) {
  .button-wrapper {
    display: block;
  }
}
</style>
