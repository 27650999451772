import { render, staticRenderFns } from "./ProfileCollapseMobile.vue?vue&type=template&id=913b7002&scoped=true&"
import script from "./ProfileCollapseMobile.vue?vue&type=script&lang=js&"
export * from "./ProfileCollapseMobile.vue?vue&type=script&lang=js&"
import style0 from "./ProfileCollapseMobile.vue?vue&type=style&index=0&id=913b7002&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "913b7002",
  null
  
)

export default component.exports