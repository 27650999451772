<template>
  <div>
    <div class="overlay" v-if="overlayStatus"></div>
    <div id="default-layout" :class="defaultLayoutClasses">
      <s-navbar v-if="usingMobileHeader && isMobile" class="soft-shadow">
        <template #left>
          <s-navbar-item class="py-2">
            <s-navbar-item v-if="hasBackButtonMobile" class="mr-4">
              <font-awesome-icon id="back-arrow" icon="arrow-left" @click="() => $router.push(backButtonMobileLink)"/>
            </s-navbar-item>
            <s-navbar-item>
              <h2 class="inter-normal-bold mb-0">{{ headerTitle }}</h2>
            </s-navbar-item>
          </s-navbar-item>
        </template>
      </s-navbar>
      <Navbar v-else ref="navbar" @closeOverlay="closeOverlay" />
      <div class="main-wrapper">
        <div class="slot-wrapper" :style="{ 'margin-bottom': $route.name === 'PaymentDetail' && '170px'}">
          <slot id="view-router"
            @triggerLoginModal="triggerLoginModal"
            @triggerRegisterModal="triggerRegisterModal"
            @toggleSearch="openInputSearch"
            @toggleModalAuthentication="toggleModalAuthentication"
            @triggerEmailVerificationModal="triggerEmailVerificationModal"
            @toggleShowBottomSheet="toggleShowBottomSheet"
            :overlay-status="overlayStatus"
          />
        </div>
        <Footer v-if="isShowFooter" />
        <!-- <FooterCopyright /> -->
        <!-- <float-action-button></float-action-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Navbar from '@/components/navbar/Navbar'
import Footer from '@/components/FooterV3'
// import FloatActionButton from '../components/tanyamu/FloatActionButton'

export default {
  components: {
    Navbar,
    Footer,
    FontAwesomeIcon
    // FloatActionButton
  },
  data: () => ({
    overlayStatus: false,
    isMobile
  }),
  computed: {
    metaModule () {
      return this.$route.meta.module
    },
    defaultLayoutClasses () {
      if (this.metaModule === 'payment' || this.metaModule === 'profile' || this.metaModule === 'ParentGuide' || this.metaModule === 'kelasku' || this.metaModule === 'discovery') {
        return 'neutral-background'
      } else {
        return ''
      }
    },
    hasBackButtonMobile () {
      return this.$route.meta.hasBackButtonMobile
    },
    headerTitle () {
      if (this.$route.name === 'BillPaymentSchemeV5') {
        return this.getPaymentSchemeV5NavbarTitle(this.$route.query)
      } else {
        return this.$route.meta.headerTitle
      }
    },
    usingMobileHeader () {
      return this.$route.meta.usingMobileHeader
    },
    backButtonMobileLink () {
      const persistBackButtonLink = localStorage.getItem('back-button-mobile-link')
      return persistBackButtonLink || this.$route.meta.backButtonMobileLink || '/'
    },
    envPlatinum () {
      return this.$store.state.envPlatinum
    },
    isShowFooter () {
      const footerHiddenPageName = ['BillPaymentSchemeV5', 'TransactionDetail']
      if (footerHiddenPageName.includes(this.$route.name) && isMobile) return false
      return true
    }
  },
  methods: {
    triggerLoginModal () {
      this.saveLoginRedirect()
      this.$router.push('/login')
    },
    triggerRegisterModal () {
      this.$router.push('/register')
    },
    toggleModalAuthentication (path) {
      this.$refs.navbar.toggleModalAuthentication(path)
    },
    openInputSearch () {
      this.$refs.navbar.toggleSearchMobile()
    },
    triggerEmailVerificationModal () {
      this.$refs.navbar.toggleEmailVerification()
    },
    searchNavbar (value) {
      this.$refs.navbar.searchNavbar(value)
    },
    activateOverlay (value) {
      // if bottomsheet persist, it cant toggle overlay off
      if (!this.$store.state.bottomSheet.isBottomSheetPersist) {
        if (!value) {
          this.$store.commit('bottomSheet/SET_SHOW_BOTTOM_SHEET_STATUS', false)
        }
        this.overlayStatus = value
      }
    },
    closeOverlay () {
      this.overlayStatus = false
    },
    openModalRegisterOAuthForm2 () {
      this.$refs.navbar.openModalRegisterOAuthForm2()
    },
    openModalRegisterForm2 () {
      this.$refs.navbar.openModalRegisterForm2()
    },
    toggleShowBottomSheet (status) {
      this.overlayStatus = status
    },
    saveLoginRedirect () {
      this.$store.commit('SET_LOGIN_REDIRECTION', {
        path: this.$route.path,
        name: this.$route.name,
        isRedirected: 1
      })
    },
    getPaymentSchemeV5NavbarTitle (query) {
      const { type, product } = query
      return type === 'multiple'
        ? 'Skema dan Pengaturan Tagihan'
        : product === 'learning-package'
          ? 'Akun dan Skema Pembayaran'
          : 'Skema Pembayaran'
    }
  },
  mounted () {
    this.$store.commit('SET_ENV_BELAJAR')
    this.$store.commit('SET_ACTIVE_ENVIRONMENT')
    this.$store.dispatch('subnavbar/initMenuList')
    let sociomile = document.getElementsByClassName('custom_widget')[0]
    if (sociomile) {
      sociomile.style.display = 'inherit'
    }
    // let hubSpotFrame = document.getElementById('hubspot-messages-iframe-container')
    // if(hubSpotFrame !== null || hubSpotFrame !== undefined){
    //   let newData = document.getElementById('hubspot-messages-iframe-container')
    //   newData.style.display = 'absolute'
    //   newData.style.width = '100px'
    //   newData.style.height = '96px'
    // }
  }
}
</script>

<style scoped>
a{
  text-decoration: none;
  color: inherit;
}

.neutral-background {
  background: var(--color-neutral-400);
}
.login-relation-background {
  background-image: url(https://cdn.sekolah.mu/assets/v2/halaman-akun/background-relasikan-akun-anak.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.main-wrapper{
  height: 100%;
}
.slot-wrapper{
  /* min-height: 100vh; */
  min-height: var(--min-height-default-layout);
}

.overlay {
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5); /*dim the background*/
  min-height: 100%;
}

@media screen and (max-width: 920px) {
  .neutral-background {
    min-height: 100vh;
  }
}
</style>
