const routes = [
  {
    path: '/living-english',
    name: 'Living English',
    meta: {
      title: 'Living English'
    },
    component: () => import(/* webpackChunkName: 'LivingTemplate' */ '../views/livingV2/LivingTemplate.vue')
  }
]

export default routes
